import React, { useState } from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={2}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 2</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>Now, continue testing your rubric with some new information. Previously, your task was to think of a health-related question. You were to find one item that might help you answer your question. This item could be an ad, an article, or some other health-related information. You could find the item from any source, such as a website, newspaper, magazine, television, or even social media. </p>
            <p>Make sure you write your question, the title of your information item, and its source in your notebook.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Ask students to get out the information item that they found. Students should record their question and source in their science notebook. If students choose a printed piece, they could tape or staple it into their notebook. If the source is from the internet, they can write down the URL so they can return to it easily. </p>
            <p>You may want to check the questions they wrote. The questions will be most useful if they have some specificity. For example, instead of the question, "Is this product good?," a more refined question would be, "Is this product helpful for treating the condition it was intended for?" or "Is this a treatment that is likely to have more benefits than risks?" </p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

